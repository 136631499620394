// src/components/Contact.js

import React, { useState, useRef } from 'react';
import '../styles/Contact.css';
import copyIcon from '../assets/copy.png';
import Toast from './Toast';

function Contact() {
    const [showToast, setShowToast] = useState(false);
    const [toastPosition, setToastPosition] = useState({ top: 0, left: 0 });
    const containerRef = useRef(null);

    const copyToClipboard = () => {
        navigator.clipboard.writeText("support@electronio.ca");
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            setToastPosition({ top: rect.bottom + window.scrollY, left: rect.left + rect.width / 2 });
        }
        setShowToast(true);
    };

    return (
        <div className="contact-container">
            <h2>Contact Us</h2>
            <div className="contact-card" ref={containerRef}>
                <div className="contact-text">
                    <p>If you have any questions or comments, please feel free to reach out to us at:</p>
                </div>
                <div className="email-container">
                    <button className="copy-button" onClick={copyToClipboard} title="Copy email to clipboard">
                        <img src={copyIcon} alt="Copy to clipboard" className="copy-icon" />
                    </button>
                    <a href="mailto:support@electronio.ca" className="contact-email">support@electronio.ca</a>
                </div>
            </div>
            <Toast
                message="Email copied to clipboard!"
                show={showToast}
                onClose={() => setShowToast(false)}
                position={toastPosition}
            />
        </div>
    );
}

export default Contact;
