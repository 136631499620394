// src/Home.js
import React from 'react';
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import productImage from '../assets/Rackmount_KVM_Front.png'; // Use appropriate image path

function Home() {
    return (
        <div className="home-container">
            <div className="home-card">
                <div className="home-content">
                    <h1 className="home-title">Electron IO</h1>
                    <p className="home-welcome">
                        Welcome to Electron IO, where innovation meets practicality. At Electron IO, we specialize in designing and developing cutting-edge KVM (Keyboard, Video, Mouse) solutions to meet the growing demands of modern IT environments.
                    </p>
                    <h2 className="home-mission-title">Our Mission</h2>
                    <p className="home-mission">
                        To provide top-tier, reliable, and efficient hardware KVM solutions that simplify and enhance the user experience in multi-computer multi-display environments. We aim to bridge the gap between complex IT infrastructure and user-friendly solutions, ensuring seamless control and management of multiple multi-display systems from a single set of peripherals.
                    </p>
                    <h2 className="home-flagship-title">Our Flagship Product</h2>
                    <Link to="/products" className="home-product-link">
                        <h3>Triple Monitor 4-Port Rackmount KVM Switch</h3>
                        <img src={productImage} alt="Triple Monitor 4-Port Rackmount KVM Switch" className="home-product-image" />
                    </Link>
                    <p className="home-product-description">
                        Our advanced rackmount KVM switch is meticulously engineered for prosumers and professionals alike, offering exceptional flexibility and control. This flagship product is designed to accommodate up to four computers, supporting 4K triple monitor setups for each connected system.
                    </p>
                    <p className="home-thank-you">
                        Thank you for choosing Electron IO. We look forward to being a part of your technological journey.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;
