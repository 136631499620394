// src/components/Toast.js

import React, { useEffect } from 'react';
import '../styles/Toast.css'; 

const Toast = ({ message, show, onClose, position }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(onClose, 3000);
            return () => clearTimeout(timer);
        }
    }, [show, onClose]);

    return (
        show && (
            <div className="toast" style={{ top: position.top, left: position.left }}>
                {message}
            </div>
        )
    );
};

export default Toast;
