// src/components/Privacy.js

import React from 'react';

function Privacy() {
    return (
        <div>
            <h2>Privacy Policy</h2>
            <p>This is the privacy policy page.</p>
        </div>
    );
}

export default Privacy;
