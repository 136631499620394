// src/components/NavBar.js

import React, { useState, useEffect, useRef } from 'react';
import '../styles/NavBar.css';
import logo from '../assets/Logo512.png';
import menuIcon from '../assets/menu.png';

function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="App-header">
            <div className="logo-container">
                <img src={logo} className="App-logo" alt="logo" />
                <h1><a href="/" className="home-link">Electron IO</a></h1>
            </div>
            <img src={menuIcon} className="menu-icon" alt="Menu" onClick={toggleMenu} />
            <nav ref={menuRef} className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/products">Products</a></li>
                    <li><a href="/downloads">Downloads</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/login">Login</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default NavBar;
