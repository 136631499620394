// src/Login.js
import React from 'react';

function Login() {
    return (
        <div>
            <h2>Login</h2>
            <p>Coming soon!</p>
        </div>
    );
}

export default Login;
