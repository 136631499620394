import React, { useState, useEffect } from 'react';
import '../styles/Products.css';
import productImageBack from '../assets/Rackmount_KVM_Back.png';
import productImageFront from '../assets/Rackmount_KVM_Front.png';

function Products() {
    const images = [productImageBack, productImageFront];
    const savedIndex = parseInt(localStorage.getItem('currentIndex'), 10);
    const initialIndex = isNaN(savedIndex) ? 0 : savedIndex;
    
    const [mainImage, setMainImage] = useState(images[initialIndex]);
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    useEffect(() => {
        localStorage.setItem('currentIndex', currentIndex);
        setMainImage(images[currentIndex]);
    }, [currentIndex, images]);  // Include 'images' in the dependency array

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    const handlePrevious = () => {
        const newIndex = (currentIndex - 1 + images.length) % images.length;
        setCurrentIndex(newIndex);
    };

    const handleNext = () => {
        const newIndex = (currentIndex + 1) % images.length;
        setCurrentIndex(newIndex);
    };

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
        setMainImage(images[index]);
    };

    return (
        <div className="products-container">
            <div className="product-card">
                <h2 className="product-title">Triple Monitor 4-Port Rackmount KVM Switch</h2>
                <div className="image-container">
                    <a href={mainImage}>
                        <img src={mainImage} alt="Rackmount KVM Switch" className="product-image" />
                        <div className="overlay-text">Temporary Image</div>
                    </a>
                    <div className="arrow left-arrow" onClick={handlePrevious}>&#10094;</div>
                    <div className="arrow right-arrow" onClick={handleNext}>&#10095;</div>
                </div>
                <div className="thumbnail-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index + 1}`}
                            className={`thumbnail ${currentIndex === index ? 'active' : ''}`}
                            onClick={() => handleThumbnailClick(index)}
                        />
                    ))}
                </div>
                <div className="product-content">
                    <div className="product-description">
                        <h3>Overview:</h3>
                        <p>
                            Our advanced rackmount KVM switch is meticulously engineered for prosumers and professionals alike, offering exceptional flexibility and control. Designed to accommodate up to four computers, this KVM switch supports 4K triple monitor setups for each connected system, featuring two DisplayPort connections and one HDMI connection per computer.
                        </p>
                    </div>
                    <div className="product-features">
                        <h3>Key Features:</h3>
                        <ul>
                            <li><span className="feature-heading">Seamless Switching:</span> Effortlessly switch between multiple multi-display computers, ensuring smooth and uninterrupted workflow.</li>
                            <li><span className="feature-heading">Triple Monitor Support:</span> Each of the four computers can connect up to three 4K monitors (two DisplayPort and one HDMI), providing expansive visual workspace.</li>
                            <li><span className="feature-heading">1U Rackmount Design:</span> The compact 1U form factor is ideal for efficient use of rack space.</li>
                            <li><span className="feature-heading">Durable Construction:</span> Built with a robust steel enclosure and high-quality gold (ENIG) PCBs, ensuring longevity and reliable performance.</li>
                            <li><span className="feature-heading">User-Friendly Remote:</span> Includes a CNC-machined aluminum remote with the same four buttons and corresponding LEDs you find on the front panel for easy switching. The remote connects via a standard 3.5mm audio cable (included), allowing for flexible placement and easy cable replacement.</li>
                            <li><span className="feature-heading">Configuration via Ethernet or USB:</span> Offers dual configuration options via a web interface (Ethernet) or a dedicated application (USB). This duality ensures adaptability to different network environments and user preferences.</li>
                            <li><span className="feature-heading">Selective Switching Configurations:</span> Allows users to enable or disable switching for USB, DisplayPort 1, DisplayPort 2, and HDMI independently, catering to specific workflow requirements.</li>
                            <li><span className="feature-heading">Customizable LED Indicators:</span> Configure the color and brightness of LEDs for each channel, providing clear visual status indicators on both the front panel and remote.</li>
                            <li><span className="feature-heading">Security Options:</span> Ethernet connectivity can be disabled via the configuration app for enhanced security.</li>
                        </ul>
                    </div>
                    <div className="buy-now-container">
                    <a href="https://www.amazon.com" className="buy-now-button">Buy Now</a>
                </div>
                </div>

            </div>
        </div>
    );
}

export default Products;
