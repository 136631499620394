// src/components/Terms.js

import React from 'react';

function Terms() {
    return (
        <div>
            <h2>Terms of Service</h2>
            <p>This is the terms of service page.</p>
        </div>
    );
}

export default Terms;
