// src/Downloads.js
import React from 'react';

function Downloads() {
    return (
        <div>
            <h2>Downloads</h2>
            <p>Coming soon!</p>
        </div>
    );
}

export default Downloads;
